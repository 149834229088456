<template>
  <div class="page-container">
    <AppChildPageHeader
      :title="pageTitle"
      :breadcrumbs="breadcrumbs"
    ></AppChildPageHeader>
    <AppSmartList
      :headers="headers"
      :actions="actions"
      :editRecordPage="editRecordPage"
    ></AppSmartList>
  </div>
</template>

<script>
import AppChildPageHeader from '@/components/base/AppChildPageHeader.vue'
import AppSmartList from '@/components/base/AppSmartList.vue'
import { ROUTE_NAME, DATA_TABLE_LAYOUT } from '@/constants'
import { i18nHelper } from '@/utils'
import {
  CONTACT_APPLICATION_GET_CONTACT_APPLICATIONS,
  CONTACT_APPLICATION_INITIAL_GET_CONTACT_APPLICATIONS_STATE
} from '@/store/contact-application.module'

export default {
  name: 'ContactApplicationList',
  components: {
    AppChildPageHeader,
    AppSmartList
  },
  data: () => ({
    pageTitle: 'contactApplication',
    breadcrumbs: [
      {
        text: 'home',
        disabled: false,
        to: { name: ROUTE_NAME.HOME }
      },
      {
        text: 'contactApplication',
        disabled: true
      }
    ],
    headers: [
      {
        text: 'no',
        value: 'no'
      },
      {
        text: 'name',
        value: 'name'
      },
      {
        text: 'phoneNumber',
        value: 'phoneNumber'
      },
      {
        text: 'email',
        value: 'email'
      },
      {
        text: 'createdAt',
        value: 'createdAt'
      },
      {
        text: 'actions',
        value: 'actions',
        sortable: false,
        layout: DATA_TABLE_LAYOUT.APP_TABLE_ACTIONS,
        actions: ['edit']
      }
    ],
    actions: {
      getList: {
        module: 'contactApplication',
        state: 'contactApplications',
        action: CONTACT_APPLICATION_GET_CONTACT_APPLICATIONS,
        title: i18nHelper.getMessage('text.getContactApplications')
      },
      initialGetListState:
        CONTACT_APPLICATION_INITIAL_GET_CONTACT_APPLICATIONS_STATE
    },
    editRecordPage: ROUTE_NAME.CONTACT_APPLICATION_DETAILS
  })
}
</script>

<style></style>
